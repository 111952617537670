<template>
  <section id="FnB">
    <div class="FnB-cnt">
      <div class="FnB-title-cnt">
        <div class="FnB-title">Silver &amp; Black Hospitality</div>
        <div class="line-divider"></div>
      </div>
      <div class="FnB-desc">
        Meet your starting line-up. Silver &amp; Black Hospitality will work
        with you to assemble a world-class, delicious menu to bring your
        culinary vision to life.
      </div>
      <div class="rep-cnt">
        <div class="repBox" v-for="rep in reps" :key="rep.name">
          <img
            :src="
              require('@/assets/images/VenueOverview/whoWeAre/reps/' +
                rep.image)
            "
            :alt="rep.name"
            class="repImg"
          />
          <div class="name-title">
            <div class="rep-name">{{ rep.name }}</div>
            <div class="rep-title" v-html="rep.title" />
          </div>
          <div class="rep-desc">
            {{ rep.description }}
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "foodAndBeverage",
  data() {
    return {
      reps: [
        {
          image: "rep-angelina.png",
          name: "Angelina Martinez",
          title: "National Sales Director, Levy Restaurants",
          description:
            "Angelina utilizes her diverse experience in the Las Vegas events industry to create world class menus and deliver best in class customer service.",
        },
        {
          image: "rep-tara.png",
          name: "Tara Sasser",
          title:
            "Area Sales Director, Silver &amp; Black Hospitality, Levy Restaurants",
          description:
            "Tara specializes in customer satisfaction, utilizing her eleven years of experience in sports and entertainment venues to offer a one-of-a kind event planning experience.",
        },
        {
          image: "rep-jenna.png",
          name: "Jenna Charles",
          title: "Catering Sales Manager, Silver &amp; Black Hospitality",
          description:
            "Jenna-Kaya brings excellent customer service to go above and beyond to create connections with efficiency and focus in building future growth.",
        },
        {
          image: "rep-manny.png",
          name: "Manny Gerardo",
          title: "Director of Banquets, Silver &amp; Black Hospitality",
          description:
            "Manny’s passion for hospitality along with his diverse background of 23 years in the food and beverage allows him to exceed every guest’s expectation and make your event the most memorable experience you ever had at a sporting venue.",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
#FnB {
  width: 100vw;
  height: 100vh;
  background: url("~@/assets/images/VenueOverview/whoWeAre/bg-foodAndBeverage.png")
    scroll no-repeat center center;
  background-size: cover;
  @media (max-width: $lg) {
    padding: 150px 0 0 0;
    height: auto;
  }
  .FnB-cnt {
    position: relative;
    display: flex;
    height: 100%;
    overflow: auto;
    flex-direction: column;
    justify-content: flex-start;
    padding: 160px 30px 160px 298px;
    @media (max-width: $lg) {
      display: none;
    }
    .FnB-title-cnt {
      display: inline-flex;
      flex-wrap: wrap;
      align-self: flex-start;
      margin-bottom: 25px;
      .FnB-title {
        width: 100%;
        margin-bottom: 25px;
        font-size: 35px;
        font-family: "ArtegraSans-Bold";
        line-height: 30px;
        letter-spacing: 2.19px;
        text-transform: uppercase;
        color: #ffffff;
      }
      .line-divider {
        position: relative;
        width: 100%;
        height: 2px;
        background: #ffffff;
        box-shadow: 0 0 9px 2px #ffffff;
      }
    }
    .FnB-desc {
      max-width: 653px;
      font-size: 16px;
      font-family: "ArtegraSans-Regular";
      line-height: 23px;
      letter-spacing: 1.07px;
      color: #ffffff;
      @media (min-width: 2000px) {
        font-size: 26px;
        line-height: 37px;
        max-width: 50%;
      }
    }
    .rep-cnt {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: flex-start;
      width: 100%;
      margin-top: 40px;
      gap: 58px;
      .repBox {
        flex: 1;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        @media (max-width: 1250px) {
          flex-basis: calc(50% - 58px);
        }
        .repImg {
          margin-bottom: 10px;
          width: 200px;
          @media (min-width: 2000px) {
            width: 300px;
          }
        }
        .rep-name {
          display: block;
          width: 100%;
          font-size: 16px;
          font-family: "ArtegraSans-Bold";
          letter-spacing: 1px;
          line-height: 30px;
          text-align: center;
          text-transform: uppercase;
          color: #ffffff;
          margin-bottom: 10px;
          @media (min-width: 2000px) {
            font-size: 30px;
            line-height: 50px;
          }
          @media (max-width: $xl) {
            font-size: 14px;
          }
          @media (max-width: $md) {
            font-size: 12px;
          }
          @media (max-width: $sm) {
            line-height: 22px;
          }
        }
        .rep-title {
          display: block;
          width: 100%;
          margin-top: -10px;
          font-size: 14px;
          font-family: "ArtegraSans-Bold";
          line-height: 18px;
          letter-spacing: 0.88px;
          text-align: center;
          text-transform: uppercase;
          color: #989898;
          @media (min-width: 2000px) {
            font-size: 26px;
            line-height: 37px;
          }
          @media (max-width: $xl) {
            font-size: 14px;
          }
          @media (max-width: $md) {
            font-size: 10px;
            line-height: 12px;
          }
        }
        .rep-desc {
          display: block;
          width: 100%;
          margin-top: 20px;
          font-size: 19px;
          font-family: "ArtegraSans-Regular";
          line-height: 25px;
          letter-spacing: 1.19px;
          text-align: center;
          color: #ffffff;
          @media (min-width: 2000px) {
            font-size: 26px;
            line-height: 37px;
          }
          @media (max-width: 1400px) {
            font-size: 16px;
          }
          @media (max-width: $xl) {
            font-size: 14px;
            line-height: 20px;
          }
          @media (max-width: $md) {
            font-size: 12px;
            line-height: 18px;
          }
        }
      }
    }
  }
}
</style>